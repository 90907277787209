import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { JoinPageContext } from "../../context/join_page_context";
import EditIcon from '@mui/icons-material/Edit';
import FlagIcon from '@mui/icons-material/Flag';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, FormGroup, InputAdornment, TextField, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ConfirmationModalTypeB from '../modals/ConfirmationModalTypeB';
import { update_selected } from "../../features/addons/addonsSlice";
import { update } from "../../features/payment/paymentSlice";
import PaymentService from "../../services/api/payment";
import initializeCollectJs from '../../services/shared/collect';
import { setTokenCookie } from '../../auth';
import {sendGAFormEvent} from "../../tracking/ga";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import InputField from "../../inputs/InputField";
import { capsAlphaNumericOrHyphenOrUnderscore } from "../../inputs/validations";

const inputFieldTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid rgb(39,39,39)`
            }
          }
        }
      }
    }
  }
})

function PaymentBox(data) {
  return (
    <>
      <div className="row center_horizontal_margin">
        <div className="col">
          <span className="small_bottom_margin bold">Card Number <span className="light_red">*</span></span>
          <div id="ccnumber" />
          {data['cardValidations']['ccnumber'] === false && <span className="error-text">{data['cardErrors']['ccnumber']}</span>}
        </div>
      </div>
      <div className="row center_horizontal_margin">
        <div className="col is-1-of-2">
          <span className="small_bottom_margin bold">Expiration Date <span className="light_red">*</span></span>
          <div id="ccexp" />
          {data['cardValidations']['ccexp'] === false && <span className="error-text">{data['cardErrors']['ccexp']}</span>}
        </div>
        <div className="col is-1-of-2">
          <span className="small_bottom_margin bold">CVV <span className="light_red">*</span></span>
          <div id="cvv" />
          {data['cardValidations']['cvv'] === false && <span className="error-text">{data['cardErrors']['cvv']}</span>}
        </div>
      </div>
    </>
  )
}

const MemorizedPaymentBox = React.memo(PaymentBox)

export default function Payment(props) {
  const club = useSelector(state => state.club.club_data)

  const store_selected_addons = useSelector(state => state.addons.selected_addons)
  const addon_charges = useSelector(state => state.addons.addons) || []
  const address = useSelector(state => state.golfer.address)
  const billing_address = useSelector(state => state.golfer.billing_address)

  const golfer = useSelector(state => state.golfer.golfer)
  const guardian = useSelector(state => state.golfer.guardian)

  const memberships = useSelector(state => state.membership.memberships)
  const selected_membership_id = useSelector(state => state.membership.selected_membership.id)
  const logged_in_user = useSelector(state => state.loggedInUserSlice.logged_in_user_data)
  const membership = useMemo(() => {
    return Object.values(memberships).filter((e) => e.id === selected_membership_id)[0]
  }, [memberships, selected_membership_id])
  const { url } = useParams()
  const [aboutRenewalOpened, set_aboutRenewalOpened] = useState(false)
  const [submitting, set_submitting] = useState(false)
  const [checkboxes, set_checkboxes] = useState({
    autoRenewal: false,
    agreedUSGATerms: false,
    agreedAssociationTerms: false
  })

  const [total, _set_total] = useState(0)
  const [customAmount, set_customAmount] = useState({})
  const [error, set_error] = useState('')
  const [selectedAddons, set_selectedAddons] = useState({ ...store_selected_addons })
  const [cardValidations, set_cardValidations] = useState({
    ccnumber: false,
    ccexp: false,
    cvv: false
  })
  const [cardErrors, setCardErrors] = useState({
    ccnumber: '',
    ccexp: '',
    cvv: ''
  })

  const [promotionalCodes, setPromotionalCodes] = useState([]);
  const [isDiscountedFee, setIsDiscountedFee] = useState(false);
  const [discountedFee, setDiscountedFee] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState("");
  const [promoCodeValidationError, setPromoCodeValidationError] = useState(false);
  
  const [collectJsInitalized, setCollectJsInitalized] = useState(false);

  const {executeRecaptcha} = useGoogleReCaptcha();

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialConfigure = useRef(true)
  const auto_renew_failed = sessionStorage.getItem('auto_renew_failed') === 'true'
  const update_card_details = sessionStorage.getItem('update_card_details') === 'true'

  const { JoinPageData } = useContext(JoinPageContext)
  const association = JoinPageData.golf_association_details
  const association_terms = JoinPageData.golf_association_terms

  const validate = useCallback((field, success, error) => {    
    set_cardValidations(prevState => {
      const new_data = {...prevState};
      new_data[field] = success;

      return {...new_data};
    });

    const required_field_errorMessages = {
      ccnumber: "Card Number is required",
      ccexp: "Required & must be a future date",
      cvv: "CVV is required",
    };
    
    setCardErrors(prevState => {
      const new_errors = {...prevState};
      new_errors[field] = error === "Field is empty" ? required_field_errorMessages[field] : error;
      
      return {...new_errors};
    });

  }, [cardValidations.ccnumber, cardValidations.ccexp, cardValidations.cvv])

  const finishSubmit = useCallback((data) => {

    let addons_data = [];
    let non_optional_charges = [];
    if (!auto_renew_failed && !update_card_details) {
      non_optional_charges = Object.fromEntries(
        addon_charges.filter(e => !e.is_optional).map(e => [e.id, e])
      )
      addons_data = Object.values({ ...selectedAddons, ...non_optional_charges }).map(e => {
        const value = {
          id: e.id
        }
        if (e.is_multiple_options) {
          value.amount = e.amount
        }
        return value
      })
    }

    if (executeRecaptcha) {
      executeRecaptcha("submit").then((token) => {
        const req_data = {
          url: url,
          club_id: club.id,
          club_membership_fee_id: membership.id,
          card_token: data.token,
          auto_renew: checkboxes.autoRenewal,
          golfer: golfer,
          guardian: guardian,
          address: address,
          billing_address: billing_address,
          addon_charges: addons_data,
          payment_provider: JoinPageData.payment_gateway_settings.payment_provider,
          transaction_email: logged_in_user.is_guardian ? logged_in_user.email : guardian.email || golfer.email,
          recaptcha_token: token,
          processing_failed_renew: auto_renew_failed,
          applied_codes: promotionalCodes.map(pc => pc.code)
        }

        if (!update_card_details) {
          PaymentService.pay(req_data).then((res) => {
            if (res.token) {
              setTokenCookie(res.token, 'golfer');
            }
            if (res.message === 'Pending golfer created!'){
              dispatch(update({
                membership: membership,
                addons: Object.values({ ...selectedAddons, ...non_optional_charges }),
                promotional_codes: promotionalCodes
              }))
              navigate('../pending');
            }
            else
            {
              dispatch(update(res.transaction));
              navigate('../receipt');
            }
          }).catch((err) => {
            try {
              const first_err = err.data.errors[Object.keys(err.data.errors)[0]][0]

              if (first_err === 'Date of birth does not exists') {
                navigate('../golfer')
              }

              set_error(first_err)
            } catch {
              try {
                set_error(err.data.error)
              } catch {
                set_error("An error has occurred")
              }
            }
          }).finally(() => {
            set_submitting(false)
          })
        }
        else {
          PaymentService.updateCard(req_data).then(res => {
            navigate('../card_updated');
          }).catch(err => {
            try {
              const first_err = err.data.errors[Object.keys(err.data.errors)[0]][0]
              set_error(first_err);
            } catch {
              try {
                set_error(err.data.error);
              } catch {
                  set_error("An error has ocurred");
                }
              }
          }).finally(() => {
            set_submitting(false);
          })
        }
      });
    }
  }, [checkboxes.autoRenewal, selectedAddons, JoinPageData, address, billing_address, club.id, golfer, guardian, membership.id, url, dispatch, navigate, addon_charges, promotionalCodes, logged_in_user.email])

  const initialize_payment = useCallback(() => {
    let tokenizationKey = null
    try {
      tokenizationKey = JoinPageData.payment_gateway_settings.tokenization_key
    } catch (e) {
      throw (e)
    }
    if (tokenizationKey) {
      try {
        const config = {
          tokenizationKey,
          variant: 'inline',
          styleSniffer: true,
          callback: (token) => {
            finishSubmit(token)
          },
          validationCallback: (f, s, _) => {
            validate(f, s, _)
          },
          customCss: {
            height: '30px',
            'font-size': 'x-large',
            'padding-left': '5px',
            'border-width': '1px',
            'border-radius': '2px'
          },
          invalidCss: {
            'border-color': 'orangered'
          },
          validCss: {
            'border-color': 'seagreen'
          },
          focusCss: {
            'border-width': '1.5px'
          },
          fields: {
            ccnumber: {
              placeholder: '****-****-****-****',
              selector: '#ccnumber'
            },
            ccexp: {
              placeholder: '**/**',
              selector: '#ccexp'
            },
            cvv: {
              placeholder: '***',
              selector: '#cvv'
            }
          }
        }
        
        window.CollectJS.configure(config);
        initialConfigure.current = false
      }
      catch (e) {
        throw (e)
      }
    }
  }, [JoinPageData, finishSubmit, validate])

  useEffect(() => {
    if (initialConfigure.current) {
      let tokenizationKey = null;

      try {
        tokenizationKey = JoinPageData.payment_gateway_settings.tokenization_key
      } catch (e) { }

      if (tokenizationKey) {
        if (collectJsInitalized) {
          try {
            initialize_payment()
          }
          catch (e) {
            navigate('../invalid_payment_gateway')
          }
        }
        else {
          initializeCollectJs(tokenizationKey, () => { setCollectJsInitalized(true);}) 
        }
      }  
    } else {
      window.CollectJS.config.callback = (token) => finishSubmit(token)
      window.CollectJS.config.validationCallback = (f, s, _) => {
        validate(f, s, _)
      }
    }
    
  }, [collectJsInitalized, JoinPageData, navigate, finishSubmit, validate])

  const set_total = useCallback((new_amount) => {
    const original = total
    _set_total(new_amount)
    if (original === 0 && new_amount > 0) {
      try {
        if (collectJsInitalized){
          initialize_payment()
        }
          
      } catch (e) { }
    }
  }, [collectJsInitalized, initialize_payment, total])
  
  useEffect(() => {
    set_cardValidations({
      ccnumber: false,
      ccexp: false,
      cvv: false
    })
    setCardErrors({
      ccnumber: '',
      ccexp: '',
      cvv: ''
    })
  }, [total > 0])

  useEffect(() => {
    let sum = 0;
    if (!auto_renew_failed) {
      const non_optional_charges = Object.fromEntries(
        addon_charges.filter(e => !e.is_optional).map(e => [e.id, e])
      )
      dispatch(update_selected({ ...selectedAddons, ...non_optional_charges }))
      const selected = Object.values({ ...selectedAddons, ...non_optional_charges })
      sum = selected.reduce((prev, cur) => {
        const amount = parseFloat(cur.amount)
        return prev + (isNaN(amount) ? 0 : amount)
      }, 0.00)
    }
    
    let totalMembershipAmount = sum + (isDiscountedFee ? discountedFee : membership.amount);
    if (totalMembershipAmount > 0)
      set_total(sum + membership.transaction_fee + (isDiscountedFee ? discountedFee : membership.amount));
    else
      set_total(0);
  }, [dispatch, selectedAddons, membership.amount, membership.transaction_fee, addon_charges, discountedFee, promotionalCodes, set_total])

  const handleSubmit = (event) => {
    event.preventDefault();
    sendGAFormEventData();
    set_submitting(true)
    window.CollectJS.startPaymentRequest();
  }

  const computeDiscount = (fee, amount, type) => {
    if (type === "percentage") {
      return Math.min(fee * (amount / 100), fee);
    } else if (type === "dollar") {
      return Math.min(amount, fee);
    } else {
      return "invalid";
    }
  }
  
  const applyPromotionalCode = () => {
    PaymentService.applyCode(
      JoinPageData.golf_association_details.id,
      {
        code: promoCode,
        applied_codes: promotionalCodes.map(pc => pc.code),
        golfer: golfer,
        club_id: club.id
      }
    )
    .then(res => {
      res = res.promotional_code;
      if (res.discount === true) {
        if (membership.amount === 0) {
          setPromoCodeError("The fee for this membership is $0.00. This offer cannot be applied.");
          return;
        }
        setPromotionalCodes([...promotionalCodes, {
          code: res.code,
          subtext: res.subtext,
          isDiscount: true,
          discountAmount: res.discount_amount,
          discountType: res.discount_type
        }]);

        const discount = computeDiscount(membership.amount, res.discount_amount, res.discount_type)
        setDiscountedFee(membership.amount - discount);
        setIsDiscountedFee(true);
      }
      else {
        setPromotionalCodes([...promotionalCodes, {
          code: res.code,
          subtext: res.subtext,
          isDiscount: false
        }])
      }
      setPromoCode("");
      setPromoCodeError("");
    })
    .catch(err => {
      setPromoCodeError(err.data.errors.code[0]);
    })
  }

  const removePromoCode = (promoCode) => {
    const promoCodeToRemove = promotionalCodes.find(pc => pc.code === promoCode);
    setPromotionalCodes(promotionalCodes.filter(code => code.code !== promoCode));

    if (promoCodeToRemove.isDiscount) {
        setDiscountedFee(null);
        setIsDiscountedFee(false);
    }

    setPromoCodeError("");
  }

  const sendGAFormEventData = () => {
    if (golfer.id) {
      const user_properties = {
        club_id: club.id.toString(),
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: golfer.id
      }
      sendGAFormEvent(user_properties, "Existing Golfer - Payment Form")
    } else {
      const user_properties = {
        club_id: club.id.toString(),
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: "-"
      }
      sendGAFormEvent(user_properties, "New Golfer - Payment Form")
    }
  }

  const renderPrice = (elem) => {
    if (!elem.is_multiple_options) {
      return `$${parseFloat(elem.amount).toFixed(2)}`
    }

    if (selectedAddons[elem.id] && selectedAddons[elem.id].amount && selectedAddons[elem.id].amount !== 'custom') {
      return `$${parseFloat(selectedAddons[elem.id].amount).toFixed(2)}`
    }

    return ''
  }

  const renderAddon = (addon) => {
    return (
      <div key={addon.id} className="small_padding_3">
        <div className="row">
          <div className="col is-3-of-4">
            <p className="bold line-height-18">{addon.name}</p>
          </div>
          <div className="col is-1-of-4 align-items-end">
            <p className="float_right line-height-18">${parseFloat(addon.amount).toFixed(2)}</p>
          </div>
        </div>
      </div>
    )
  }

  const addPriceToMultipleOptions = (id, price) => {
    const new_addons = { ...selectedAddons }
    new_addons[id] = {
      ...new_addons[id],
      amount: price
    }
    set_selectedAddons(new_addons)
  }

  const flushCustomAmount = (id) => {
    const new_selected = { ...selectedAddons }
    new_selected[id] = {
      ...new_selected[id],
      amount: parseFloat(customAmount[id])
    }
    const new_custom_amount = { ...customAmount }
    delete new_custom_amount[id]
    set_customAmount(new_custom_amount)
    set_selectedAddons(new_selected)
  }

  const validateAddons = useCallback(() => {
    if (auto_renew_failed || update_card_details)
      return true;
    const custom_amounts = Object.values(selectedAddons).filter(e =>
      e.is_multiple_options && (e.amount === 'custom' || e.amount === null)
    )
    return custom_amounts.length === 0
  }, [selectedAddons])

  const terms_link = () => {
    const disabled = association_terms.terms_url === '' || association_terms.terms_url === null || association_terms.terms_url === undefined
    const disabledClass = disabled ? 'disabled' : '';
    return (
      <a
        href={'https://' + association_terms.terms_url}
        target="_blank"
        rel="noreferrer"
        className={disabledClass} >
        Terms of Service
      </a>
    )
  }

  const policy_link = () => {
    const disabled = association_terms.policy_url === '' || association_terms.policy_url === null || association_terms.policy_url === undefined
    const disabledClass = disabled ? 'disabled' : '';
    return (
      <a
        href={"https://" + association_terms.policy_url}
        target="_blank"
        rel="noreferrer"
        className={disabledClass} >
        Privacy Policy
      </a>
    )
  }

  const validatePromoCode = (value) => {
    const validationError = capsAlphaNumericOrHyphenOrUnderscore(value);
    setPromoCodeError(validationError);
    setPromoCodeValidationError(!!validationError)
  }

  return (
    <Fragment>
      <div className="payment-membership-title center_horizontal_margin">
        <div className="payment_container center_horizontal_margin">
          { !update_card_details &&
          <div>
            <div className="large_bottom_margin bold">
              <p>Review payment info and adjust where necessary.</p>
            </div>
            <div className="small_bottom_margin dark_blue">
              <FlagIcon className="center_icon" />
              <span className="bold small-left-padding">MEMBERSHIP INFORMATION</span>
            </div>
            <div className="payment_container center_horizontal_margin create-ghin-padding">
              <div className="small_padding">
                <div className="row">
                  <div className="col is-3-of-4">
                    <p className="bold line-height-18">{membership.name}</p>
                    <p className="font-size-14 line-height-18"><i>Annual Fee</i></p>
                  </div>
                  <div className="col is-1-of-4">
                    <p className="float_right line-height-18">
                      ${parseFloat(membership.amount).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            {
              !auto_renew_failed &&
              <Fragment>
                <hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

                <div className="small_bottom_margin dark_blue">
                  <LocalOfferIcon className="center_icon" />
                  <span className="bold small-left-padding">DISCOUNTS / PROMOTIONAL CODES</span>
                </div>
                <div className="payment_container center_horizontal_margin">
                  <div className="small_padding">
                    <div className="row">
                      <div className="col is-3-of-4 promo_code_col">
                        <InputField
                          size="almost-big responsive-on-tablet pedding-lr-10px"
                          id="promo_code"
                          label={"Add Discount or Promotional Code"}
                          value={promoCode}
                          onChange={(event) => {
                            setPromoCode(event.target.value.toUpperCase());
                            validatePromoCode(event.target.value.toUpperCase());
                          }}
                          onKeyDown={(event) => {
                            if (event.code === 'Enter' && !promoCodeValidationError)
                              applyPromotionalCode();
                          }}
                          autoComplete={"off"}
                        />
                        {promoCodeError !== "" && <div className="error-text medium-margin-left">{promoCodeError}</div>}
                      </div>
                      <div className="col is-1-of-4" style={{paddingTop: "36px"}}>
                        <button
                          style={{height: "43px"}}
                          disabled={promoCode === "" || promoCodeValidationError}
                          onClick={() => applyPromotionalCode()}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  
                    {promotionalCodes.map((promoCode) => {
                      return <div className="discount-box" key={promoCode.code}>
                              <span className="success-icon"><CheckCircleIcon /></span>
                              <div className="discount-details">
                                <div className="discount-code semi-bold line-height-18">{promoCode.code}</div>
                                <div className="discount-amount">{promoCode.subtext}</div>
                              </div>
                              <button className="remove-button" onClick={() => removePromoCode(promoCode.code)}>Remove</button>
                            </div>
                    })}

                  </div>
                </div>
              </Fragment>
              }
              
              <hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

              <div className="small_bottom_margin dark_blue">
                <AddBoxIcon className="center_icon" />
                <span className="bold small-left-padding">OPTIONAL ITEMS / DONATIONS</span>
              </div>

              {!auto_renew_failed && addon_charges && addon_charges.filter(e => e.is_optional).length > 0 && <Fragment>
                <div style={{marginLeft: "35px"}}>
                  <div className="row">
                    <div className="col">
                      <p style={{height: "10px"}}></p>
                      <p className="line-height-18 font-size-14"><i>Please select from the following options that you wish to include in your initial membership. If fees apply, they will be one-time charges.</i></p>
                    </div>
                  </div>
                  <FormGroup>
                    {addon_charges.filter(e => e.is_optional).map((e) => {
                      return <Fragment key={e.id}>
                        <span className="important-full-width">
                          <div className="row medium-margin-bottom">
                            <div className="col is-1-of-8">
                                <Checkbox
                                  name={`addon_${e.id}`}
                                  id={`addon_${e.id}`}
                                  sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                  checked={selectedAddons[e.id] !== undefined || !e.is_optional}
                                  disabled={!e.is_optional}
                                  onClick={() => {
                                    const newAddons = { ...selectedAddons }
                                    if (newAddons[e.id]) {
                                      delete newAddons[e.id]
                                    }
                                    else {
                                      const copy = { ...e }
                                      if (copy.is_multiple_options) {
                                        copy.amount = null
                                      }
                                      newAddons[e.id] = copy
                                    }
                                    set_selectedAddons(newAddons)
                                  }}
                              />
                            </div>

                            <div className="col is-5-of-8 tiny-padding-top">
                              <strong className="break-word">{e.name}</strong>
                              <p className="line-height-18 font-size-14"><i className="break-word">{e.description}</i></p>
                            </div>
                            <div className="col is-1-of-4 tiny-padding-top align-items-end" style={{ visibility: renderPrice(e) === '' ? 'hidden' : '', paddingRight: '43px' }}>
                              <p className="line-height-18">{renderPrice(e) === '' ? "-" : renderPrice(e)}</p>
                            </div>
                          </div>
                        </span>
                        {selectedAddons[e.id] &&
                          selectedAddons[e.id].is_multiple_options &&
                          !selectedAddons[e.id].amount &&
                          <Fragment>
                            <div className="row">
                              <div className="col is 1-of-3">
                                <button className="multiple_choice_box" onClick={() => addPriceToMultipleOptions(e.id, 5)}>
                                  $5
                                </button>
                              </div>
                              <div className="col is 1-of-3">
                                <button className="multiple_choice_box" onClick={() => addPriceToMultipleOptions(e.id, 10)}>
                                  $10
                                </button>
                              </div>
                              <div className="col is 1-of-3">
                                <button className="multiple_choice_box" onClick={() => addPriceToMultipleOptions(e.id, 20)}>
                                  $20
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col is 1-of-3">
                                <button className="multiple_choice_box" onClick={() => addPriceToMultipleOptions(e.id, 100)}>
                                  $100
                                </button>
                              </div>
                              <div className="col is 1-of-3">
                                <button className="multiple_choice_box" onClick={() => addPriceToMultipleOptions(e.id, 200)}>
                                  $200
                                </button>
                              </div>
                              <div className="col is 1-of-3">
                                <button className="multiple_choice_box" onClick={() => addPriceToMultipleOptions(e.id, 'custom')}>
                                  Custom
                                </button>
                              </div>
                            </div>
                          </Fragment>}
                        {selectedAddons[e.id] &&
                          selectedAddons[e.id].is_multiple_options &&
                          selectedAddons[e.id].amount === 'custom' && <div>
                            <p className="medium_bottom_margin">Please input a custom amount</p>
                            <div className="row">
                              <div className="col is 1-of-2">
                                <ThemeProvider theme={inputFieldTheme}>
                                  <TextField
                                    id="custom_amount"
                                    size="small"
                                    variant="outlined"
                                    type={"number"}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    onWheel={(e) => {
                                      e.target.blur()
                                    }}
                                    onChange={(event) => {
                                      const new_amounts = {
                                        ...customAmount
                                      }
                                      var new_value = event.target.value
                                      if (new_value < 0 && event.target.value !== '') new_value = ''
                                      if (new_value !== "") new_value =
                                        new_value.match(/^\d*[.]?\d{0,2}$/)
                                          ? new_value
                                          :
                                          new_amounts[e.id]
                                      new_amounts[e.id] = new_value
                                      set_customAmount(new_amounts)
                                    }}
                                    value={customAmount[e.id] === undefined ? '' : customAmount[e.id]}
                                    onKeyDown={(event) => {
                                      if (event.code === 'Enter') {
                                        flushCustomAmount(e.id)
                                      }
                                    }}
                                  />
                                </ThemeProvider>
                              </div>
                              <div className="col is 1-of-2 center_vertical_margin">
                                <Button
                                  height={"50px"}
                                  variant="text"
                                  style={{width: "50%"}}
                                  onClick={() => {
                                    flushCustomAmount(e.id)
                                  }}
                                >Submit</Button>

                              </div>
                            </div>

                          </div>

                        }
                      </Fragment>
                    })}
                  </FormGroup>
                </div>
              </Fragment>}

              <hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

            <div className="small_bottom_margin dark_blue">
              <ShoppingCartIcon className="center_icon" />
              <span className="bold small-left-padding">PURCHASE INFORMATION</span>
            </div>
            <div className="payment_container large_bottom_margin center_horizontal_margin create-ghin-padding">
              <div className="small_padding_3">
                <div className="row">
                  <div className="col is-3-of-4">
                    <p className="bold line-height-18">{membership.name}</p>
                    <p className="font-size-14 line-height-18"><i>Annual Fee</i></p>
                  </div>
                  <div className="col is-1-of-4 align-items-end">
                    { isDiscountedFee ?
                      <div className="codes-display">
                        <p style={{color: 'red'}}>${parseFloat(discountedFee).toFixed(2)}</p>
                        <p className="original-fee">Reg <span style={{textDecoration: 'line-through'}}>${parseFloat(membership.amount).toFixed(2)}</span></p>
                        {
                          promotionalCodes.filter(pc => pc.isDiscount).map((pc, index) => {
                            return (
                              <p key={pc.code} className="discount-code-text">{pc.code} Discount Applied</p>
                            )
                          })
                        }
                      </div>
                      :
                      <p className="float_right line-height-18">
                        ${parseFloat(membership.amount).toFixed(2)}
                      </p>
                    }
                   
                  </div>
                </div>
              </div>

              {!auto_renew_failed && addon_charges.filter(e => !e.is_optional).map(e => renderAddon(e))}
              {!auto_renew_failed && Object.values(selectedAddons).filter(e => e.is_optional).map(e => renderAddon(e))}

              <div className="small_padding_3" style = {{paddingTop: `${!auto_renew_failed && addon_charges && addon_charges.filter(e => e.is_optional).length > 0 ? "15px" : "0px" }`}}>
                <div className="row">
                  <div className="col is-3-of-4">
                    <p className="bold line-height-18">Transaction Fee</p>
                  </div>
                  <div className="col is-1-of-4 align-items-end">
                    <p className="float_right line-height-18">${total > 0 ? parseFloat(membership.transaction_fee).toFixed(2) : 0..toFixed(2)}</p>
                  </div>
                </div>
              </div>
              <div className='small_top_margin grey_background black_border'>
                <div className="row small_padding_4">
                  <div className="col is-3-of-4">
                    <span className="bold">TOTAL TO BE CHARGED: </span>
                  </div>
                  <div className="col is-1-of-4 align-items-end">
                    <span className="bold">${total.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }

          <hr style={{border: '0.5px solid #cccccc', marginTop: '25px', marginBottom: '25px'}}></hr>

          <div>
            <form onSubmit={handleSubmit}>
              {total > 0 && <div className="dark_blue medium_bottom_margin">
                <CreditCardIcon className="center_icon" />
                <span className="bold small-left-padding">CREDIT CARD INFORMATION</span>
              </div>}

              <div className="row">
                <div className="col unset-flex center_vertical_margin display-block">
                  <span className="bold x-large-left-right-padding">Billing Address</span>
                </div>
                <div className="col is-1-of-1 align-items-end">
                  <button
                    className="btn lnk light_blue"
                    style={{ alignSelf: 'self-end' }}
                    onClick={() => {
                      navigate('../billing_address', {
                        state: {
                          previous_page: 'payment',
                          next_page: 'payment'
                        }
                      })
                    }}
                  ><EditIcon className="center_icon align-right small_icon" />Edit</button>
                </div>
              </div>
              <div className="payment_container center_horizontal_margin large_bottom_margin x-large-left-right-padding">
                <div className="row">
                  <div className="col">
                    <span className="small_bottom_margin">{golfer.first_name} {golfer.last_name}</span>
                    <span className="small_bottom_margin">{billing_address.street_1}</span>
                    <span className="small_bottom_margin">{billing_address.street_2}</span>
                    <span className="small_bottom_margin">{billing_address.city && `${billing_address.city}, `} {billing_address.state} {billing_address.zip}</span>
                  </div>
                </div>
              </div>

              <div className="payment_container center_horizontal_margin medium_bottom_margin large-left-right-padding">
                <div className={`row_bottom_margin ${total === 0 ? 'hide' : ''}`}>
                <MemorizedPaymentBox cardValidations={cardValidations} cardErrors={cardErrors} />
                </div>
                {(isDiscountedFee ? discountedFee > 0 : membership.amount > 0) && club.auto_renewal_enabled && !update_card_details && <Fragment>
                <div className="row grey_background">
                  <div className="col is-1-of-8">
                    <Checkbox
                      value={checkboxes.autoRenewal}
                      onChange={(e) => {
                        if (!submitting) {
                          set_checkboxes({
                            ...checkboxes,
                            autoRenewal: !checkboxes.autoRenewal
                          })
                        }
                      }}
                    />
                  </div>
                  <div className="col is-7-of-8">
                    <label htmlFor="agree_policy" style={{ marginTop: '1px' }}>
                      <label className="light_red">
                        <strong>
                          Automatic Renewal and Cancellation
                        </strong>
                      </label>
                      <br /><br />
                        <label className="small-font">
                        By enabling automatic renewal, your annual membership is set to renew automatically using your payment method on file. You will be notified in advance of your renewal. You may manage or cancel your membership at any time, but payment for your current membership may be non-refundable. Any membership cancellation will take effect when your current membership ends, and no further charges will be made. For more information, see the &nbsp;
                        <a
                          href={"#"}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => {
                            e.preventDefault()
                            set_aboutRenewalOpened(true)
                        }}>
                          <span className='bold-500'>Terms of Use</span>
                        </a>.
                      </label>
                      {/* <p>
                        {club.name}
                      </p>
                      <p>
                        {club.primary_contact}
                      </p>
                      <p>
                        {club.email}
                      </p>
                      <p>
                        {club.phone}
                      </p> */}
                    </label>
                  </div>
                </div>
                </Fragment>}
                <div className="row grey_background">
                  <div className="col">
                    <div className="row">
                      <div className="col is-1-of-8">
                        <Checkbox
                          value={checkboxes.agreedUSGATerms}
                          onChange={(e) => {
                            if (!submitting) {
                              set_checkboxes({
                                ...checkboxes,
                                agreedUSGATerms: !checkboxes.agreedUSGATerms
                              })
                            }
                          }}
                        />
                      </div>
                      <div className="col is-7-of-8">
                        <label htmlFor="agree_policy" style={{ marginTop: '1px' }}>By signing up, I agree to the USGA <a
                          href={"https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-terms-of-service.html"}
                          target="_blank"
                          rel="noreferrer"
                          className='bold-500'
                        >Terms of Service</a> and <a
                          href={"https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-privacy-policy.html"}
                          target="_blank"
                          rel="noreferrer"
                          className='bold-500'
                        > Privacy Policy
                          </a>.</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col is-1-of-8">
                        <Checkbox
                          value={checkboxes.agreedAssociationTerms}
                          onChange={(e) => {
                            if (!submitting) {
                              set_checkboxes({
                                ...checkboxes,
                                agreedAssociationTerms: !checkboxes.agreedAssociationTerms
                              })
                            }
                          }}
                        />
                      </div>
                      <div className="col is-7-of-8">
                        <label htmlFor="agree_policy" style={{ marginTop: '1px' }}>By signing up, I agree to the
                          {' ' + association.name} <span className='bold-500'>{terms_link()}</span> and <span className='bold-500'>{policy_link()}</span>.</label>
                      </div>
                    </div>
                  </div>
                </div>

                {!submitting ?
                  <div className="row" style={auto_renew_failed ? {justifyContent: 'center'} : {}}>
                    {!auto_renew_failed && !update_card_details &&
                      <div className="col is-1-of-2">
                        <button
                          className="btn smaller fill gray"
                          type={"button"}
                          style={{margin: 'auto', width: '-webkit-fill-available'}}
                          onClick={() => {
                            let page = Object.keys(guardian).length > 0 ? 'guardian' : 'address';
                            if (golfer.id)
                              navigate(`../membership`, {state: {previous_page: 'golfer_details'}});
                            else
                              navigate(`../membership`, {state: {previous_page: page}});
                          }}
                        >
                          Go Back
                        </button>
                      </div>
                    }
                    <div className={`col is-1-of-2 ${update_card_details ? 'centered' : ''}`}>
                      <button
                        className="btn smaller fill cardinal"
                        type={"submit"}
                        id={"submit-button"}
                        style={{ margin: 'auto', width: '-webkit-fill-available' }}
                        disabled={
                          submitting || !checkboxes.agreedUSGATerms ||
                          !checkboxes.agreedAssociationTerms || !validateAddons() ||
                          (total > 0 && !Object.values(cardValidations).reduce((prev, cur) => prev && cur, true))
                        }
                        onClick={(e) => {
                          if (total === 0) {
                            e.preventDefault()
                            set_submitting(true)
                            finishSubmit({})
                          }
                        }}
                      >
                        { update_card_details ? 'Update Card Details' : 'Submit Application' }
                      </button>
                    </div>
                  </div>
                  :
                  <div className="row"> 
                    <div className='loader margin-auto'></div>
                  </div>
                  
                }
              </div>
            </form>
          </div>
        </div>
      </div>
      <ConfirmationModalTypeB
        wideClass={'responsive'}
        openModal={error.length > 0}
        onConfirmAction={() => { set_error('') }}
        onCancelAction={() => { set_error('') }}
        closeModal={() => { set_error('') }}
        question={error}
        confirmLabel={"Confirm"}
        modalIcon={ErrorOutlineIcon}
      />
      <ConfirmationModalTypeB
        wideClass={'responsive medium-max-width'}
        openModal={aboutRenewalOpened}
        onConfirmAction={() => { set_aboutRenewalOpened(false) }}
        onCancelAction={() => { set_aboutRenewalOpened(false) }}
        closeModal={() => { set_aboutRenewalOpened(false) }}
        question={<Fragment>
          <p className="font-24 left-align light_red"><strong>Automatic Renewal</strong></p>
          <br />
          <p className="left-align black-text">
            After your initial membership term, your membership will automatically renew and your credit card or debit card payment will be processed on or near your initial purchase anniversary at the current membership rate, plus applicable processing fees and sales tax. Your membership will continue and you will be charged the applicable fees until you cancel. Your club reserves the right to change annual membership rates.
            <br /><br />
            We will send you an annual email notice before each renewal membership term stating the term and current membership rates. If you do nothing, we will charge the payment method you selected.
            <br /><br />
            To avoid incurring charges for a renewal membership term, you must cancel before your current membership ends by opting out via the GHIN Mobile App or by contacting your club using the phone number or email address listed below.
          </p>
          <br />
          <p className="font-24 left-align light_red"><strong>Cancellation</strong></p>
          <p className="left-align black-text">
            <br />
            While you may cancel your membership at any time, payment for your current membership rate may be non-refundable depending on your association’s refund policy. Upon cancellation, and if your association’s refund policy states that your current membership is non-refundable, then your membership will continue until the end of your current membership term after which the cancellation will take effect and no further charges will be made.
            <br/><br/>
            Upon cancellation, and if your association’s refund policy states that your current membership is refundable, then your refund will include your current membership rate and any optional donations or fees, but it may not include a refund of the transaction fee. Contact your association for additional questions regarding their cancellation and refund policies.
          </p>
        </Fragment>}
        confirmLabel={"Close"}
        buttonClassName={"important-full-width modal-grey-button "}
      />
    </Fragment>
  )
}
