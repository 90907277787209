import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { JoinPageContext } from "../../context/join_page_context";
import { update } from "../../features/payment/paymentSlice";
import {useDispatch} from 'react-redux'
import InputField from "../../inputs/InputField";
import GolferService from "../../services/api/golfer";
import store from "../../store";
import phone_svg from "./../../assets/img/logos/gps_image.png"
import { clear_guardian } from '../../features/golfer/golferSlice';
import { clear_memberships, clear_selected_membership } from '../../features/membership/membershipSlice';
import { clear_addons, clear_selected } from '../../features/addons/addonsSlice';
import ConfirmationModalTypeB from "../modals/ConfirmationModalTypeB";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import jwt_decode from "jwt-decode";
import { clear_logged_in_user } from "../../features/logged_in_user/loggedInUserSlice";
import { sendGAFormEvent } from "../../tracking/ga";
import { isPresent } from  "../../shared/emptyValuesHelper"
import * as Yup from "yup";

const tokenName = `jr-token-${process.env.REACT_APP_BASENAME}`;
const tokenTypeName = `jr-token-type-${process.env.REACT_APP_BASENAME}`;

export default function Receipt() {
  const [token, set_token] = useState(sessionStorage.getItem(tokenName))
  const [passwordSetModalOpen, set_passwordSetModalOpen] = useState(false)
  const [error, set_error] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let cookie = sessionStorage.getItem(tokenName);
  var decoded = cookie ? jwt_decode(cookie) : null;

  const [password, set_password] = useState("")
  const [confirmationPassword, set_confirmationPassword] = useState("")
  const { JoinPageData } = useContext(JoinPageContext)

  const [errors, setErrors] = useState(false);
  const passwordValidationSchema = { 'Password': Yup.string().min(6, "Password must be at least 6 characters") };
  const confirmPasswordValidationSchema = { 'Confirm Password': Yup.string().oneOf([password, ''], "Your password does not match"),};
  const validationSchema = Yup.object({
    ...passwordValidationSchema,
    ...confirmPasswordValidationSchema
  });

  useEffect(() => {
    const validateFields = () => {
      validationSchema
        .isValid({
          'Password': password,
          'Confirm Password': confirmationPassword,
        })
        .then(res => {
          setErrors(!res);
        })
    }
    validateFields();
  }, [password, confirmationPassword, validationSchema]);


  const clearData = useCallback(() => {
    dispatch(clear_logged_in_user())
    // dispatch(clear_golfer());
    // dispatch(clear_email());
    dispatch(clear_guardian());
    // dispatch(clear_address());
    // dispatch(clear_billing_address());
    dispatch(clear_addons());
    dispatch(clear_selected());
    // dispatch(clear_club());
    dispatch(clear_memberships());
    dispatch(clear_selected_membership());
  }, [dispatch])

  const data = useMemo(() => {
    const store_data = store.getState()
    const values = {
      club_data: store_data.club.club_data,
      payment: store_data.payment.payment,
      golfer_data: store_data.golfer.golfer,
      address: store_data.golfer.address
    }
    dispatch(update({ ...store_data.payment.payment, confirmed_payment: true }));
    clearData();

    return values
  }, [clearData, dispatch])

  const create_digital_profile = useCallback(() => {   
    
    const golfer_data = data.golfer_data;
    const club_data = data.club_data;
    
    if (golfer_data.id) {
      const user_properties = {
        club_id: club_data.id.toString(),
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: golfer_data.id
      }
      sendGAFormEvent(user_properties, "Existing Golfer - Create Digital Form")
    } else {
      const user_properties = {
        club_id: club_data.id.toString(),
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: "-"
      }
      sendGAFormEvent(user_properties, "New Golfer - Create Digital Form")
    }
    
    GolferService.createDigitalProfile(data.payment.golfer.id, {
      password
    }).then(() => {
      set_token("")
      sessionStorage.removeItem(tokenName);
      set_passwordSetModalOpen(true)
    }).catch((err) => {
      try {
        const first_err = err.data.errors[Object.keys(err.data.errors)[0]][0]
        set_error(first_err)
      }
      catch {
        set_error("An error has occurred")
      }
    })
  }, [data.payment.golfer.id, password])

  const parsePhone = (phoneNumber) => {
    let number = phoneNumber;
    if (number && number.length === 10) {
      const aux = number.split('');
      number = `(${aux.slice(0, 3).join('')}) ${aux.slice(3, 6).join('')}-${aux.slice(6, 10).join('')}`
    }

    return number;
  }
  
  const club_address = (club) =>{
    return `${club.city}, ${club.state} ${club.zip_code}`
  }
  
  const transaction = data.payment
  const club_data = data.club_data
  const address = data.address
  const golfer = data.payment.golfer
  const golferDataFromStore = data.golfer_data
  const join_type = transaction ? transaction.join_type : 'new_affiliation'
  const promotions = transaction.promotional_codes.filter(pc => !pc.is_discount).map(pc => pc.code);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const getNavigationPath = () => {
    const slice_end = sessionStorage.getItem('entered_from_assoc_level') === 'true' ? 4 : 6;
    return window.location.href.split('/').slice(0, slice_end).join('/');
  }

  const PromoMessage = () => {
    let message;
    if (promotions.length === 1)
      message = `Promotion: ${promotions[0]} has been applied.`
    else {
      const last_promotion = promotions.pop()
      message = `Promotions: ${promotions.join(", ")} and ${last_promotion} have been applied.`
    }

    return message;
  };
  
  const totalDiscountedMembershipAmount = () => {
    const club_fee_details = transaction.club_membership;
    const club_fee_amount = parseFloat(isPresent(club_fee_details.discounted_amount) ? club_fee_details.discounted_amount : club_fee_details.amount);
    
    const assoc_fee_details = transaction.association_membership;
    const assoc_fee_amount = parseFloat(isPresent(assoc_fee_details.discounted_amount) ? assoc_fee_details.discounted_amount : assoc_fee_details.amount);
    
    return (club_fee_amount + assoc_fee_amount).toFixed(2) ;
  }
  
  const totalMembershipAmount = () => {
    const m_total = parseFloat(transaction.club_membership.amount) + parseFloat(transaction.association_membership.amount);
    return (m_total).toFixed(2);
  }

  return (
    <Fragment>
      <div className="payment-membership-title center_horizontal_margin top-margin large_bottom_margin">
        <div className="center_horizontal_margin">
          <div className="line-height-18" style={{ width: "90%", margin: "auto" }}>
            <p className="med_bottom_margin semi-bold">
              {`${golfer.first_name} ${golfer.last_name}, thank you for ${join_type.includes('renew') ? 'renewing with' : 'joining'}  the ${club_data.name}!`}
            </p>
            <p className="med_bottom_margin semi-bold">
              Your GHIN Number is: <span className="big-text">{transaction.golfer.id}</span>
            </p>
            {transaction.total_amount !== 0 && <p className={promotions.length === 0 ? "large_bottom_margin" : "med_bottom_margin"}>
              A charge of <span className="semi-bold">${parseFloat(transaction.total_amount).toFixed(2)}</span> was applied to the credit card
              that is on file in your profile.
            </p>}
            {promotions.length > 0 && <p className="promotion-code-text semi-bold med_bottom_margin">
              {PromoMessage()}
            </p>}
            {(transaction.total_amount !== 0 || transaction.is_discounted) && <table className="receipt-table">
              <tbody>
              <tr>
                <th>{transaction.club_membership.name}</th>
                <td>
                  {
                    transaction.is_discounted ?
                    <div className="codes-display-c">
                      <p style={{color: 'red'}}> ${totalDiscountedMembershipAmount()}</p>
                      <p className="original-fee">Reg <span style={{textDecoration: 'line-through'}}>
                        ${totalMembershipAmount()}</span></p>
                      {
                        transaction.promotional_codes.filter(pc => pc.is_discount).map((pc, index) => {
                          return (
                            <p key={pc.code} className="discount-code-text">{pc.code} Discount Applied</p>
                          )
                        })
                      }
                    </div>
                    :
                    <span>${totalMembershipAmount()}</span>
                  }
                </td>
              </tr>
              {transaction.addon_charges.map((addon, index) => (
                  <tr key={index}>
                    <th>{addon.name}</th>
                    <td>${parseFloat(addon.amount).toFixed(2)}</td>
                  </tr>
              ))}
              {transaction.total_amount !== 0 && <tr>
                <th>{transaction.transaction_fee?.description || "Transaction Fee"}</th>
                <td>${parseFloat(transaction.transaction_fee?.amount || 0).toFixed(2)}</td>
              </tr> }
              <tr>
                <th>Total:</th>
                <td className="semi-bold">${parseFloat(transaction.total_amount).toFixed(2)}</td>
              </tr>
              </tbody>
            </table>}
            <br/>
            <p className="med_bottom_margin tiny-top-margin line-height-18">
              {`For your convenience, a confirmation email `}
              { transaction.total_amount !== 0 && `with the following details `}
              {`has been sent${golfer.email ? ` to ${golfer.email}` : ''}.`}
            </p>
            <br></br>
          </div>
          {golferDataFromStore.email !== null &&
            !golferDataFromStore.is_minor &&
            !golferDataFromStore.has_digital_profile &&
            JoinPageData.digital_profile && token && decoded && decoded.ghin_number &&
            <div>
              <br></br>
              <div className="row box-header">
                Create your GHIN Account
              </div>
              <div className="grey_background create-account-box center_horizontal_margin line-height-18">
                <div className="mobile">
                  <div className="row" style={{marginBottom: "20px"}}>
                    <div className="col">
                      <p className="font-size-16">
                        As a member of the <strong>{club_data.name}</strong>, you have access
                        to ghin.com and the GHIN App to post scores, track your stats, and access GPS and course
                        maps.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col is-5-of-11">
                      <img src={phone_svg} alt={""}
                           style={{
                             height: '300px',
                             width: '100%',
                             marginTop: '2px',
                             borderRadius: '5px'
                           }}
                      />
                    </div>
                    <div className="col is-6-of-11 inline-block">
                      <strong style={{paddingBottom: "10px"}}>Set up your password today!</strong>
                      <InputField
                        containerClassName="password"
                        label="Password"
                        size='medium-small'
                        type='password'
                        validationSchema={Yup.object(passwordValidationSchema)}
                        onChange={(e) => {
                          set_password(e.target.value)
                        }}
                        value={password}
                      />
                      <InputField
                        containerClassName="confirm-password"
                        label="Confirm Password"
                        size='medium-small'
                        type='password'
                        validationSchema={Yup.object(confirmPasswordValidationSchema)}
                        onChange={(e) => {
                          set_confirmationPassword(e.target.value)
                        }}
                        value={confirmationPassword}
                      />
                      <button
                        style={{width: "100%"}}
                        className="btn fill cardinal x-smaller"
                        type={"submit"}
                        id={"submit-button"}
                        disabled={(password !== confirmationPassword) || password.length === 0}
                        onClick={() => {
                          create_digital_profile()
                        }}
                      >
                        Create Account
                      </button>
                    </div>
                  </div>
                  <div className="row no-left-padding">
                    <div className="col center-text">
                    </div>
                  </div>
                </div>

                <div className="tablet">
                  <div className="row" style={{justifyContent: "center"}}>
                    <div className="col is-4-of-11" style={{marginTop: "20px"}}>
                      <img
                        src={phone_svg}
                        alt={""}
                        style={{
                          borderRadius: '5px'
                        }}/>
                    </div>
                    <div className="col is-6-of-11" style={{marginLeft: "22px", marginTop: "20px"}}>
                      <p className="font-size-16" style={{paddingBottom: "15px"}}>
                        As a member of the <strong>{club_data.name}</strong>, you have access
                        to ghin.com and the GHIN App to post scores, track your stats, and access GPS and course
                        maps.</p>
                      <strong style={{paddingBottom: "17px"}}>Set up your password today!</strong>
                      <input
                        hidden
                        type={"email"}
                        value={golfer.email}
                      />
                      <InputField
                        containerClassName="password no-margin"
                        label="Password"
                        size='fit'
                        type='password'
                        validationSchema={Yup.object(passwordValidationSchema)}
                        onChange={(e) => {
                          set_password(e.target.value)
                        }}
                        value={password}
                      />
                      <InputField
                        containerClassName="confirm-password no-margin"
                        label="Confirm Password"
                        size='fit'
                        type='password'
                        validationSchema={Yup.object(confirmPasswordValidationSchema)}
                        onChange={(e) => {
                          set_confirmationPassword(e.target.value)
                        }}
                        value={confirmationPassword}
                      />
                      <button
                        className="btn fill cardinal fit tiny-top-margin"
                        type={"submit"}
                        id={"submit-button"}
                        disabled={(password !== confirmationPassword) || password.length <= 5}
                        onClick={() => {
                          create_digital_profile()
                        }}
                      >
                        Create Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='white-box'>
                <div className='download-app-container'>
                  <div className='white-mobile-container'>
                    <div className='title'>
                      Download the GHIN app to get started today
                    </div>
                    <div className='space'></div>
                    <div className='logos'>
                      <a href='https://apps.apple.com/us/app/ghin-mobile/id491796218' className='apple-logo'></a>
                      <a href='https://play.google.com/store/apps/details?id=com.advancedmobile.android.ghin&hl=en&gl=US' className='google-logo'></a>
                    </div>
                    <div className='small-space'></div>
                  </div>
                </div>
                <div className='logos-container'>
                  <div className='white-logos-container'>
                    <div className='logos'>
                      <div className='usga-logo'></div>
                      <div className='usga-text'>
                        GOLF HANDICAP
                        <br/>
                        INFORMATION NETWORK
                      </div>
                      <div className='sentry-logo'></div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              <br></br>
            </div>
          }
          <button
            className="btn fit fill cardinal centered_btn"
            onClick={() => {window.location.replace(getNavigationPath())}}
          >
            Return to {sessionStorage.getItem('entered_from_assoc_level') === 'true' ? JoinPageData.golf_association_details.name : club_data.name}
          </button>
        </div>
      </div>

      <ConfirmationModalTypeB
        wideClass={'responsive'}
        openModal={passwordSetModalOpen}
        onConfirmAction={() => set_passwordSetModalOpen(false)}
        onCancelAction={() => set_passwordSetModalOpen(false)}
        closeModal={() => set_passwordSetModalOpen(false)}
        question={"Password has been successfully set."}
        confirmLabel={"Ok"}
        modalIcon={CheckCircleOutlineIcon}
      />

      <ConfirmationModalTypeB
        wideClass={'responsive'}
        openModal={error.length > 0}
        onConfirmAction={() => { set_error('') }}
        onCancelAction={() => { set_error('') }}
        closeModal={() => { set_error('') }}
        question={error}
        confirmLabel={"Confirm"}
        modalIcon={ErrorOutlineIcon}
      />
    </Fragment>
  )
}
